import Types from "src/Constants/Types";

const initialState: AppReducerType = {
  loading: true,
  errorConnection: false,
  currentPage: "welcome",
  method: "",
};

type LoadingAction = { type: typeof Types.SET_LOADING; value: boolean };
type ErrorConnectionAction = { type: typeof Types.SET_ERROR_CONNECTION; value: boolean };
type PageAction = { type: typeof Types.SET_CURRENT_PAGE; value: string };
type MethodAction = { type: typeof Types.SET_METHOD; value: string };

type AppReducerAction = LoadingAction | ErrorConnectionAction | PageAction | MethodAction;

export default function appReducer(state = initialState, action: AppReducerAction) {
  switch (action.type) {
    case Types.SET_LOADING: {
      return { ...state, loading: action.value };
    }
    case Types.SET_ERROR_CONNECTION: {
      return { ...state, errorConnection: action.value };
    }
    case Types.SET_CURRENT_PAGE: {
      return { ...state, currentPage: action.value };
    }
    default:
      return state;
  }
}
