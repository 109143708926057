import Types from "src/Constants/Types";

const initialState: PageSelectsReducerType = {
  loadingPose: false,
  loadingStyle: false,
  loadingResult: false,
  imagesRafa: null,
  yearSelected: null,
  poseSelected: null,
  styleSelected: null,
};

type LoadingPoseAction = { type: typeof Types.SET_LOADING_POSE; value: boolean };
type LoadingStyleAction = { type: typeof Types.SET_LOADING_STYLE; value: boolean };
type LoadingResultAction = { type: typeof Types.SET_LOADING_RESULT; value: boolean };
type ImagesRafaAction = { type: typeof Types.SET_IMAGES_RAFA; value: ImagesRafaType };
type MergeImagesRafaAction = { type: typeof Types.MERGE_IMAGES_RAFA; value: Partial<ImagesRafaType> };
type YearSelectedAction = { type: typeof Types.SET_YEAR_SELECTED; value: ImageRafaType };
type PoseSelectedAction = { type: typeof Types.SET_POSE_SELECTED; value: ImageRafaType };
type StyleSelectedAction = { type: typeof Types.SET_STYLE_SELECTED; value: ImageRafaType };

type PageSelectsReducerAction =
  | LoadingPoseAction
  | LoadingStyleAction
  | LoadingResultAction
  | ImagesRafaAction
  | MergeImagesRafaAction
  | YearSelectedAction
  | PoseSelectedAction
  | StyleSelectedAction;

export default function pageSelectsReducer(state = initialState, action: PageSelectsReducerAction) {
  switch (action.type) {
    case Types.SET_LOADING_POSE: {
      return { ...state, loadingPose: action.value };
    }
    case Types.SET_LOADING_STYLE: {
      return { ...state, loadingStyle: action.value };
    }
    case Types.SET_LOADING_RESULT: {
      return { ...state, loadingResult: action.value };
    }
    case Types.SET_IMAGES_RAFA: {
      return { ...state, imagesRafa: action.value };
    }
    case Types.MERGE_IMAGES_RAFA: {
      return { ...state, imagesRafa: { ...state.imagesRafa, ...(action.value as {}) } };
    }
    case Types.SET_YEAR_SELECTED: {
      return { ...state, yearSelected: action.value };
    }
    case Types.SET_POSE_SELECTED: {
      return { ...state, poseSelected: action.value };
    }
    case Types.SET_STYLE_SELECTED: {
      return { ...state, styleSelected: action.value };
    }

    default:
      return state;
  }
}
