import React, { Suspense } from "react";

import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import Fallback from "src/Components/Common/Fallback";
import store from "src/Reducers/store";

import "./Assets/fonts/Nunito_Sans.css";
import "./Assets/css/index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const App = React.lazy(() => import("src/App"));

root.render(
  <Provider store={store}>
    <Suspense fallback={<Fallback show={true} />}>
      <App />
    </Suspense>
  </Provider>
);
