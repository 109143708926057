import Types from "src/Constants/Types";

const initialState: PagePromptsReducerType = {
  mode: "FREE_TEXT",
  freeTextLoading: false,
  promptsImage: "",
  prompts: "",
  promptsError: "",
  assistiveLoading: false,
  assistiveImage: "",
  assistiveError: "",
  location: "",
  weather: "",
};

type ModeAction = { type: typeof Types.SET_MODE; value: string };
type FreeTextLoadingAction = { type: typeof Types.SET_FREE_TEXT_LOADING; value: boolean };
type PromptsImageAction = { type: typeof Types.SET_PROMPTS_IMAGE; value: string };
type PromptsAction = { type: typeof Types.SET_PROMPTS; value: string };
type PromptsErrorAction = { type: typeof Types.SET_PROMPTS_ERROR; value: string };
type AssistiveLoadingAction = { type: typeof Types.SET_ASSISTIVE_LOADING; value: boolean };
type AssistiveImageAction = { type: typeof Types.SET_ASSISTIVE_IMAGE; value: string };
type AssistiveErrorAction = { type: typeof Types.SET_ASSISTIVE_ERROR; value: string };
type LocationAction = { type: typeof Types.SET_LOCATION; value: string };
type WeatherAction = { type: typeof Types.SET_WEATHER; value: string };

type PagePromptsReducerAction =
  | ModeAction
  | FreeTextLoadingAction
  | PromptsImageAction
  | PromptsAction
  | PromptsErrorAction
  | AssistiveLoadingAction
  | AssistiveImageAction
  | AssistiveErrorAction
  | LocationAction
  | WeatherAction;

export default function pagePromptsReducer(state = initialState, action: PagePromptsReducerAction) {
  switch (action.type) {
    case Types.SET_MODE: {
      return { ...state, mode: action.value };
    }
    case Types.SET_FREE_TEXT_LOADING: {
      return { ...state, freeTextLoading: action.value };
    }
    case Types.SET_PROMPTS_IMAGE: {
      return { ...state, promptsImage: action.value };
    }
    case Types.SET_PROMPTS: {
      return { ...state, prompts: action.value };
    }
    case Types.SET_PROMPTS_ERROR: {
      return { ...state, promptsError: action.value };
    }
    case Types.SET_ASSISTIVE_LOADING: {
      return { ...state, assistiveLoading: action.value };
    }
    case Types.SET_ASSISTIVE_IMAGE: {
      return { ...state, assistiveImage: action.value };
    }
    case Types.SET_ASSISTIVE_ERROR: {
      return { ...state, assistiveError: action.value };
    }
    case Types.SET_LOCATION: {
      return { ...state, location: action.value };
    }
    case Types.SET_WEATHER: {
      return { ...state, weather: action.value };
    }

    default:
      return state;
  }
}
