const TYPES = {
  //App
  SET_LOADING: "SET_LOADING",
  SET_ERROR_CONNECTION: "SET_ERROR_CONNECTION",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_METHOD: "SET_METHOD",
  //Selects
  SET_LOADING_POSE: "SET_LOADING_POSE",
  SET_LOADING_STYLE: "SET_LOADING_STYLE",
  SET_LOADING_RESULT: "SET_LOADING_RESULT",
  SET_IMAGES_RAFA: "SET_IMAGES_RAFA",
  MERGE_IMAGES_RAFA: "MERGE_IMAGES_RAFA",
  SET_YEAR_SELECTED: "SET_YEAR_SELECTED",
  SET_POSE_SELECTED: "SET_POSE_SELECTED",
  SET_STYLE_SELECTED: "SET_STYLE_SELECTED",
  SET_RESULT_RAFA: "SET_RESULT_RAFA",

  //Prompts
  SET_MODE: "SET_MODE",
  SET_FREE_TEXT_LOADING: "SET_FREE_TEXT_LOADING",
  SET_PROMPTS_IMAGE: "SET_PROMPTS_IMAGE",
  SET_PROMPTS: "SET_PROMPTS",
  SET_PROMPTS_ERROR: "SET_PROMPTS_ERROR",
  SET_ASSISTIVE_LOADING: "SET_ASSISTIVE_LOADING",
  SET_ASSISTIVE_IMAGE: "SET_ASSISTIVE_IMAGE",
  SET_LOCATION: "SET_LOCATION",
  SET_WEATHER: "SET_WEATHER",
  SET_ASSISTIVE_ERROR: "SET_ASSISTIVE_ERROR",
};

export default TYPES;
