import Lottie from "react-lottie-player";
import loadingJson from "src/Assets/images/loading.json";

interface LoadingProps {
  show: boolean;
}

const Fallback = (props: LoadingProps) => {
  const { show } = props;
  return (
    <>
      {show && (
        <div style={styles.container}>
          <Lottie loop animationData={loadingJson} play style={{ width: 250, height: 250 }} />
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    width: "100vw",
    height: "100vh",
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: "#111",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default Fallback;
